
import axios from "axios";
import { useState } from "react";
import "./MDFooter.css";


const MDFooter = () => { 
const [oldName, newName] = useState();
const [oldEmail, newEmail] = useState();
const [oldMob, newMob] = useState();
const [oldMess, newMess] =useState();

const HandleSubmitSwap =(event) => { 
  event.preventDefault();
  if(Boolean(oldName) && Boolean(oldEmail) && Boolean(oldMob) && Boolean(oldMess)) {
    const SwapData = { 
      aname : oldName,
      aemail : oldEmail,
      amob : oldMob,
      amess : oldMess
    }
    axios.post("https://walkinstabilitychamber.in/walkapi/Enquiry.php", SwapData).then(response => { alert(response.data); event.target.reset();});
    
  } else { 
    alert("All Fields Required");
    event.reset();
  }
}

    return(<>
    <>


    <>
  <div className="clearfix" />
  <section id="facility_sec">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          {" "}
          <a href="#">
            <h2>
              ad pharma <br />
              <span>infrastructure</span>
            </h2>
          </a>{" "}
        </div>
        <div className="col-xs-12 col-sm-6">
          <p>
            “an ISO 9001:2015 certified company
            <br />
            <span>Started in 2013”</span>{" "}
          </p>
        </div>
        <div className="clearfix" />
        <div className="facility_img">
          
        </div>
        <div className="clearfix" />
        <p className="para_info">
          Keeping in mind our motto of achieving continually better level of
          customer satisfaction ,AD PHARMA EQUIPMENT PVT. LTD. maintains a very
          professional approach in its working &amp; complies with applicable
          National / International codes &amp; Standards , upgrades the
          knowledge &amp; skills of its personal through...
        </p>
      </div>
    </div>
  </section>
</>



  {/* <link
    href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css"
    rel="stylesheet"
    id="bootstrap-css"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
    rel="stylesheet"
    type="text/css"
  /> */}
  <div id="fh5co-main">
    
    <div
      className="fh5co-narrow-content animate-box fadeInLeft animated"
      data-animate-effect="fadeInLeft"
    >
      <div className="row">
        <div className="col-md-4 col-md-offset-1">
           
          <h1>Get In Touch</h1>
        </div>
      </div>
 
      <form onSubmit={HandleSubmitSwap}>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name" onChange={(event)=> { newName(event.target.value); }} required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email" onChange={(event)=> { newEmail(event.target.value); }}
                    className="form-control"
                    placeholder="Email"  required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text" onChange={(event)=> { newMob(event.target.value); }}
                    className="form-control"
                    placeholder="Phone" required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <textarea
                    name="" onChange={(event)=> { newMess(event.target.value); }}
                    id="message"
                    cols={30}
                    rows={7}
                    className="form-control"
                    placeholder="Message"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary btn-md"
                    defaultValue="Send Message"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</>


<section id="bootom_sec">
    <div className="container">

    <a href="https://www.adpharmaequipment.com/" target="_blank" rel="noreferrer"><h5 style={{"color":"white"}}>AD PHARMA EQUIPMENT PVT. LTD. 2022 | All rights reserved</h5></a>

    </div>
</section>

    </>)
}

export default MDFooter;