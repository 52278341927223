const PStab = () =>  {
    return(<>
    
    <>
  {/* Carousel
    ================================================== */}
  <div
    id="myCarousel"
    className="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <div className="carousel-inner" role="listbox">
      {" "}
      <img
        src="images/inner_head.jpg"
        className="img-responsive"
        alt="ad pharma"
      />
      <div className="title">
        <h2>
          <span>PHOTO STABILITY CHAMBER</span>
        </h2>
      </div>
    </div>
  </div>
  <section id="abt" className="section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h3 className="wow fadeIn" data-wow-delay="0.1s">
            Technical Specification
          </h3>
          <div className="table-responsive">
            {" "}
            <table>
              <tbody>
                <tr>
                  <th scope="col">Specification</th>
                  <th scope="col">Temperature </th>
                  <th scope="col">Humidity </th>
                </tr>
                <tr>
                  <td>Range</td>
                  <td>Accuracy</td>
                  <td>Uniformity</td>
                </tr>
                <tr>
                  <td>20°C to 60°C </td>
                  <td>+/- 0.2°C </td>
                  <td>+/- 1.0°C </td>
                </tr>
                <tr>
                  <td>40%RH to 85%RH</td>
                  <td>+/- 2.0% </td>
                  <td>Uniformity +/- 3.0%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-4">
          <img src="images/bod_incubator.jpg" alt="ad pharma" />
        </div>
      </div>
      <hr />
      <div className="table-responsive">
        <table>
          <tbody>
            <tr>
              <th scope="col">Models</th>
              <th scope="col">Capacity</th>
              <th scope="col">Internal size in MM</th>
              <th scope="col">External size in MM</th>
              <th scope="col">Tray Qty</th>
              <th scope="col">Tray size in MM</th>
              <th scope="col">Total Load</th>
            </tr>
            <tr>
              <td>AD/PSC-150 </td>
              <td>150</td>
              <td>700Wx500Dx450h</td>
              <td>825Wx960Dx1350H</td>
              <td>1</td>
              <td>625Wx395Dx30H</td>
              <td>3.5KW</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <h3 className="wow fadeIn" data-wow-delay="0.1s">
        Standard Features
      </h3>
      <strong>Panel Construction</strong>
      <p>
        Tongue and groove type PUF insulated sandwich panel with cam lock. The
        Equipment interior is constructed with stainless steel 304 grade
        finish-4 and exterior is constructed with pre coated GI sheet/ stainless
        steel 304 grade finish-4.
      </p>
      <hr />
      <strong>Construction</strong>
      <p>
        Double wall inner tank and outer body is dynamically designed from
        strength and working point of view. The Equipment interior is
        constructed with stainless steel 304 grade mirror finish and exterior is
        constructed with powder coated GI sheet/ stainless steel 304 grade
        finish-4.
      </p>
      <hr />
      <strong>Tray</strong>
      <p>
        Stainless steel perforated tray with intervals of ½" height adjustment
        for better sample storage.
      </p>
      <hr />
      <strong>Door</strong>
      <p>
        Heavy duty toughened double glass door installed with imported hinges
        and lock for easy operation.
      </p>
      <hr />
      <strong>UV &amp; Florescent lights</strong>
      <ul>
        <li>
          - As per ICH Guidelines-1.2 Million Lux/Hours of visible light and 200
          watt Hr/m2 of UV light. Auto shut Off after specified exposure level
        </li>
        <li>
          - Individual PLC based timer is provided to switch ON/OFF UV and
          Florescent lights as required.
        </li>
        <li>
          - UV light is switched off automatically once inner glass door is
          opened.
        </li>
        <li>
          - Samples can be tested under Fluorescent light or U.V. lights
          separately as well as combine.
        </li>
      </ul>
      <hr />
      <strong>PLC based control system</strong>
      <ul>
        <li> - Customers are our highest priority.</li>
        <li>- 4.3” HMI with TFT LCD Colour touch screen</li>
        <li>- Communication via Ethernet</li>
        <li>- PLC based auto tune facility</li>
        <li>- PLC Based Safety cut off</li>
        <li>- User based Alphanumeric password protection through HMI</li>
        <li>- Rotronic make 4 to 20 mA sensors</li>
      </ul>
    </div>
  </section>
</>


    </>)
}

export default PStab;