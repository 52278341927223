import MDFooter from "./Componets/MDFooter";
import PStab from "./Componets/PStab";
import WBod from "./Componets/WBod";
import WCCabinet from "./Componets/WCCabinet";

const App = () => { 
    const handleScroll = () => {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0, 
          behavior: 'smooth',
        });
      }
    return(<>

{/* Nav */}
<div className="navbar-wrapper">
  <div className="nav-top">
    
  
    <div className="container-fluid">

      <p>

      <a className="" href="index.html">
  <img src="images/logo.png" style={{"height":"50px"}} alt="ad pharma" className="img-responsive" />
</a>

        <span className="mail">
          <span className="glyphicon glyphicon-envelope" />
          <a href="mailto:sales@adigrp.co.in">Mail ID: info@adpharmaequipment.com</a>
        </span>
        <span className="call">
          <span className="glyphicon glyphicon-earphone" />
          Contact Now: +91 9673905522{" "}
        </span>
        <span className="call">
          
          <button className="btn btn-primary" onClick={handleScroll}>GET IN TOUCH</button>
        </span>
      </p>
    </div>
  </div>
</div>


{/* Nav */}

<>
  {/* Carousel
    ================================================== */}
  <div
    id="myCarousel"
    className="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <div className="carousel-inner" role="listbox">
      {" "}
      <img
        src="images/inner_head.jpg"
        className="img-responsive"
        alt="ad pharma"
      />
      <div className="title">
        <h2>
          <span>WALK-IN STABILITY / HUMIDITY CHAMBER</span>
        </h2>
      </div>
    </div>
  </div>
  <section id="abt" className="section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h3 className="wow fadeIn" data-wow-delay="0.1s">
            Technical Specification
          </h3>
          <div className="table-responsive">
            {" "}
            <table>
              <tbody>
                <tr>
                  <td>Temperature range : 20°C to 60°C</td>
                  <td>Accuracy ≤ +/- 0.2°C</td>
                  <td>Uniformity ≤ +/- 1.0°C*</td>
                </tr>
                <tr>
                  <td>Rel. Humidity : range : 40%RH to 90%RH</td>
                  <td>Accuracy ≤ +/- 1.0% </td>
                  <td>Uniformity ≤ +/- 3.0%*</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <strong>Standard equipment's operating conditions</strong>
          <p>
            25°C / 60%RH &nbsp; 30°C / 65%RH &nbsp; 40°C / 75%RH &nbsp; 30°C /
            75%RH{" "}
          </p>
          <hr />
          <strong>Standard equipment's operating conditions</strong>
          <p>25°C / 40%RH &nbsp; 30°C / 35%RH &nbsp; 40°C / 25%RH </p>
          <hr />
        </div>
        <div className="col-sm-4">
          <img
            className="img-responsive"
            src="images/walkingChamber.jpg"
            alt="ad pharma"
          />{" "}
        </div>
      </div>
      <div className="clearfix" />
      <div className="table-responsive">
        <table>
          <tbody>
            <tr>
              <th scope="col">Models</th>
              <th scope="col">Capacity</th>
              <th scope="col">Internal size in MM</th>
              <th scope="col">External size in MM</th>
              <th scope="col">Rack Qty</th>
              <th scope="col">Tray Qty</th>
              <th scope="col">Tray size in MM</th>
              <th scope="col">Connected Load</th>
            </tr>
            <tr>
              <td>AD/WSC-400</td>
              <td>4000</td>
              <td>2000Wx1000Dx2000H</td>
              <td>2160Wx1600Dx2200H</td>
              <td>4</td>
              <td>10</td>
              <td>740Wx625Dx30H</td>
              <td>4.0 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-600</td>
              <td>6000</td>
              <td>2000Wx1500Dx2000H</td>
              <td>2160Wx2100Dx2200H</td>
              <td>4</td>
              <td>20</td>
              <td>600Wx625Dx30H</td>
              <td>4.25 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-800</td>
              <td>8000</td>
              <td>2000Wx2000Dx2000H</td>
              <td>2160Wx2600Dx2200H</td>
              <td>4</td>
              <td>20</td>
              <td>850Wx625Dx30H</td>
              <td>4.25 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-1000</td>
              <td>10000</td>
              <td>2000Wx2500Dx2000H</td>
              <td>2160Wx3100Dx2200H</td>
              <td>6</td>
              <td>30</td>
              <td>725Wx625Dx30H</td>
              <td>4.25 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-1200</td>
              <td>12000</td>
              <td>2000Wx3000Dx2000H</td>
              <td>2160Wx3600Dx2200H</td>
              <td>6</td>
              <td>30</td>
              <td>890Wx625Dx3OH</td>
              <td>4.25 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-1600 </td>
              <td>16000</td>
              <td>2000Wx4000Dx2000H </td>
              <td>2160Wx4600Dx2200H</td>
              <td>8</td>
              <td>40</td>
              <td>900Wx625Dx3OH</td>
              <td>5.5 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-2000</td>
              <td>20000</td>
              <td>2000Wx4000Dx2500H</td>
              <td>2160Wx4600Dx2700H</td>
              <td>8</td>
              <td>48</td>
              <td>900Wx625Dx3OH</td>
              <td>6.0 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-2500</td>
              <td>25000</td>
              <td>2300Wx4500Dx2500H</td>
              <td>2460Wx5100Dx2700H</td>
              <td>10</td>
              <td>60</td>
              <td>815Wx750Dx30H</td>
              <td>6.0 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-3000</td>
              <td>30000</td>
              <td>4000Wx3000Dx2500H</td>
              <td>4160Wx3600Dx2700H</td>
              <td>12</td>
              <td>84</td>
              <td>890Wx625Dx30H</td>
              <td>9.0 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-4000</td>
              <td>40000</td>
              <td>4000Wx4000Dx2500H</td>
              <td>4160Wx4600Dx2700H</td>
              <td>16</td>
              <td>96</td>
              <td>900Wx625Dx30H</td>
              <td>9.0 KW</td>
            </tr>
            <tr>
              <td>AD/WSC-5000 </td>
              <td>50000</td>
              <td>4500Wx4500Dx2500H</td>
              <td>4660Wx5100Dx2700H</td>
              <td>20</td>
              <td>120</td>
              <td>820Wx725Dx3OH</td>
              <td>12.75 KW</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        * Measured with high accuracy data-logger at sample storage area with
        standard Set Point.
      </p>
      <p>
        {" "}
        Custom built Chambers are available in any dimensions and capacities.
      </p>
      <hr />
      <h3 className="wow fadeIn" data-wow-delay="0.1s">
        Standard Features
      </h3>
      <strong>Panel Construction</strong>
      <p>
        Tongue and groove type PUF insulated sandwich panel with cam lock. The
        Equipment interior is constructed with stainless steel 304 grade
        finish-4 and exterior is constructed with pre coated GI sheet/ stainless
        steel 304 grade finish-4.
      </p>
      <hr />
      <strong>Tray and Rack</strong>
      <p>
        Duly electro polished stainless steel wire mesh tray and heavy duty
        square pipe rack with adjustable tray location.
      </p>
      <hr />
      <strong>Door</strong>
      <p>
        Heavy duty toughened double glass door installed with imported hinges
        and lock for easy operation.
      </p>
      <hr />
      <strong>Backup System</strong>
      <p>
        Equipment is installed with PLC based auto change over backup system for
        Refrigeration system and Steam Generator system.
      </p>
      <hr />
      <strong>PLC based control system</strong>
      <p>
        Advanced PLC program with built-in Auto-Tuning, PLC Processor based
        computing and Interactive Easy-to-Use HMI.
      </p>
      {/*<ul>
 <li> - Customers are our highest priority.</li>

<li>- Controlling system - PLC</li>

<li>- 7'' HMI with TFT LCD Colour touch screen</li>

<li>- Communication via Ethernet</li>

<li>- PLC based auto tune facility</li>

<li>- PLC based Safety cut off</li>

<li>- User based Alphanumeric password protection through HMI</li>

<li>- Rotronic make 4 to 20 mA sensors</li>
</ul>*/}
      <hr />
      <strong>Documentation</strong>
      <ul>
        <li>
          {" "}
          <b>Equipment</b> : DQ, IQ, OQ with PLC Validation, PQ-Conforming to
          ISPE recommendations, User Manual.
        </li>
        <li>
          {" "}
          <b>Software :</b> IQ, OQ, User Manual
        </li>
      </ul>
      <hr />
      <div className="clearfix" />
      <div className="row">
        <div className="col-sm-3">
          <img src="images/m2.jpg" className="img-responsive" alt="ad pharma" />
        </div>
        <div className="col-sm-3">
          <img src="images/m3.jpg" className="img-responsive" alt="ad pharma" />
        </div>
        <div className="col-sm-3">
          <img src="images/m4.jpg" className="img-responsive" alt="ad pharma" />
        </div>
      </div>
    </div>
  </section>
</>




    <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
       <h1> WALK-IN COLD CHAMBER/COOLING CABINET</h1>
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <WBod />
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        <h1>WALK-IN BOD INCUBATOR</h1>
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse"
      aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <WCCabinet />
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
       <h1>PHOTO STABILITY CHAMBER</h1>
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse"
      aria-labelledby="headingThree"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <PStab />
      </div>
    </div>
  </div>
</div>

    <MDFooter />

    </>)
}

export default App;