import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <BrowserRouter>
                <Routes>
                                <Route path='/' element={<App />} />
                                <Route path='/stability' element={<App />} />
                                <Route path='/walkinstability' element={<App />} />
                                <Route path='/incubator' element={<App />} />
                                <Route path='/bod' element={<App />} />
                                <Route path='/hodairoven' element={<App />} />
                                <Route path='/photostability' element={<App />} />
                                <Route path='/contact' element={<App />} />
                                <Route path='/adpharma' element={<App />} />
                                <Route path='/pharmaequipment' element={<App />} />
                                <Route path='/manufacturer' element={<App />} />
                                <Route path='/adigrp' element={<App />} />
                                <Route path='/coldchamber' element={<App />} />
                                <Route path='/plc' element={<App />} />
                                <Route path='/deepfreeezer' element={<App />} />
                                <Route path='/laboven' element={<App />} />
                                <Route path='/calibration' element={<App />} />
                                <Route path='/validation' element={<App />} />
                                <Route path='/calibrationandvalidation' element={<App />} />
                                <Route path='*' element={<App />} />
                </Routes>
        </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

