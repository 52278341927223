const WCCabinet = () => { 
    return(<>
    <>
  {/* Carousel
    ================================================== */}
  <div
    id="myCarousel"
    className="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <div className="carousel-inner" role="listbox">
      {" "}
      <img
        src="images/inner_head.jpg"
        className="img-responsive"
        alt="ad pharma"
      />
      <div className="title">
        <h2>
          <span>WALK-IN BOD INCUBATOR</span>
        </h2>
      </div>
    </div>
  </div>
  <section id="abt" className="section">
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h3 className="wow fadeIn" data-wow-delay="0.1s">
            Technical Specification
          </h3>
          <div className="table-responsive">
            {" "}
            <table>
              <tbody>
                <tr>
                  <th scope="col">Specification </th>
                  <th scope="col">Temperature </th>
                </tr>
                <tr>
                  <td>Range</td>
                  <td>20°C to 60°C</td>
                </tr>
                <tr>
                  <td>Accuracy</td>
                  <td>+/- 0.2°C</td>
                </tr>
                <tr>
                  <td>Uniformity</td>
                  <td>+/- 1.0°C</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-4">
          <img
            className="img-responsive"
            src="images/walkingBod.jpg"
            alt="ad pharma"
          />{" "}
        </div>
      </div>
      <hr />
      <div className="table-responsive">
        {" "}
        <table>
          <tbody>
            <tr>
              <th scope="col">Models</th>
              <th scope="col">Capacity</th>
              <th scope="col">Internal size in MM</th>
              <th scope="col">External size in MM</th>
              <th scope="col">Rack Qty</th>
              <th scope="col">Tray Qty</th>
              <th scope="col">Tray size in MM</th>
              <th scope="col">Total Load</th>
            </tr>
            <tr>
              <td>AD/WBO-600 </td>
              <td>6000</td>
              <td>2000Wx1500Dx2000H</td>
              <td>2160Wx2000Dx2200H</td>
              <td>4 </td>
              <td>20</td>
              <td>625Wx625Dx30H</td>
              <td>5KW</td>
            </tr>
            <tr>
              <td>AD/WBO-800 </td>
              <td>8000</td>
              <td>2000Wx2000Dx2000H</td>
              <td>2160Wx2500Dx2200H </td>
              <td>4</td>
              <td>20</td>
              <td>850Wx625Dx30H</td>
              <td>5KW</td>
            </tr>
            <tr>
              <td>AD/WBO-1000</td>
              <td>10000</td>
              <td>2000Wx2500Dx2000H</td>
              <td>2160Wx3000Dx2200H</td>
              <td>6</td>
              <td>30</td>
              <td>725Wx625Dx30H </td>
              <td>5.5KW</td>
            </tr>
            <tr>
              <td>AD/WBO-1200</td>
              <td>12000</td>
              <td>2000Wx3000Dx2000H </td>
              <td>2160Wx3500Dx2200H</td>
              <td>6</td>
              <td>30</td>
              <td>900Wx625Dx30H</td>
              <td>5.5KW</td>
            </tr>
            <tr>
              <td>AD/WBO-1600 </td>
              <td>16000</td>
              <td>2000Wx4000Dx2000H</td>
              <td>2160Wx4500Dx2200H </td>
              <td>8</td>
              <td>40</td>
              <td>900Wx625Dx30H</td>
              <td>6KW</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</>

    </>)
}

export default WCCabinet;